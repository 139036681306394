// export const USER_EMAIL_PLACEHOLDER = '{{USER_EMAIL}}';

import { NewsletterButtonState } from '../../../shared/enums/newsletter';
import { type NewsletterReducerState } from '../../reducers/newsletterReducer';

type NewsletterConfig = {
    userEmailPlaceholder: string;
    initialState: NewsletterReducerState;
};

export const newsletterConfig: NewsletterConfig = {
    userEmailPlaceholder: '{{USER_EMAIL}}',
    initialState: {
        success: false,
        buttonState: NewsletterButtonState.Enabled,
        errorText: '',
        confirmationContent: '',
    },
};
