import { useField } from 'formik';

import { Input } from '@jsmdg/yoshi';
import { getValidationState } from './util';

type FormikInputProps = {
    readonly name: string;
    readonly className?: string;
    readonly placeholder?: string;
    readonly disabled?: boolean;
    readonly errorText?: string;
    readonly hasRequiredMarker?: boolean;
};

const FormikInput = ({
    className,
    disabled = false,
    errorText,
    hasRequiredMarker = false,
    name,
    placeholder,
}: FormikInputProps): JSX.Element => {
    const [field, meta] = useField(name);
    const { error: validationError, touched } = meta;

    const error = errorText || validationError;

    return (
        <Input
            {...field}
            name={name}
            placeholder={placeholder}
            disabled={disabled}
            helperText={errorText || error}
            showHelperText={!!errorText || !!error}
            validationState={getValidationState(touched, error)}
            hasRequiredMarker={hasRequiredMarker}
            className={className}
        />
    );
};

export { FormikInput };
