import axios from 'axios';

interface NewsletterUnsubscribeData {
    email: string;
    reason: string;
    additionalData: {
        emailId?: string | null;
        launchListId?: string | null;
        userId?: string | null;
    };
}

async function newsletterUnsubscribe(data: NewsletterUnsubscribeData): Promise<unknown> {
    const path = '/api/newsletter/unsubscribe';
    return axios.post(path, data);
}

export { newsletterUnsubscribe };
