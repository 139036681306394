import { ValidationStates } from '@jsmdg/yoshi';

export const getValidationState = (
    isTouched: boolean,
    error?: string,
): ValidationStates | undefined => {
    if (isTouched && error) {
        return ValidationStates.Error;
    }

    return undefined;
};
