import { useReducer } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button, ButtonColor, ButtonType, Wysiwyg } from '@jsmdg/yoshi';
import {
    NewsletterButtonState,
    NewsletterReducerActionType,
} from '../../../../shared/enums/newsletter';
import { newsletterReducer } from '../../../reducers/newsletterReducer';
import { newsletterUnsubscribe } from '../../../services/newsletterUnsubscribe';
import { FormikInput, FormikSelect } from '../../Formik';
import { newsletterConfig } from '../constants';
import { messages as subscribeMessages } from '../Subscribe/i18n';

const messages = defineMessages({
    emailLabel: {
        defaultMessage: 'Deine E-Mail Adresse',
    },
    reasonLabel: {
        defaultMessage: 'Abmeldegrund',
    },
    reasonsNone: {
        defaultMessage: 'Bitte Grund auswählen',
    },
    reasonsDisplay: {
        defaultMessage: 'Die E-Mails werden nicht richtig dargestellt.',
    },
    reasonsAmount: {
        defaultMessage: 'Ich bekomme generell zu viele E-Mails.',
    },
    reasonsDiscount: {
        defaultMessage: 'Ich habe mich nur wegen dem Willkommens-Rabatt angemeldet.',
    },
    reasonsContent: {
        defaultMessage: 'Der Inhalt der E-Mails interessiert mich nicht.',
    },
    reasonsFrequency: {
        defaultMessage: 'Die E-Mail Frequenz ist zu hoch.',
    },
});

type NewsletterUnsubscribeProps = {
    readonly confirmationText: string;
    readonly formText: string;
    readonly email?: string;
    readonly additionalData?: {
        emailId?: string;
        launchListId?: string;
        userId?: string;
    };
};

const NewsletterUnsubscribe = ({
    additionalData,
    confirmationText,
    email = '',
    formText,
}: NewsletterUnsubscribeProps): JSX.Element => {
    const intl = useIntl();

    const reasons = [
        { label: intl.formatMessage(messages.reasonsNone), value: 'Ohne' },
        {
            label: intl.formatMessage(messages.reasonsDisplay),
            value: 'Darstellung',
        },
        { label: intl.formatMessage(messages.reasonsAmount), value: 'Menge' },
        {
            label: intl.formatMessage(messages.reasonsDiscount),
            value: 'Willkomens-Rabatt',
        },
        { label: intl.formatMessage(messages.reasonsContent), value: 'Inhalt' },
        { label: intl.formatMessage(messages.reasonsFrequency), value: 'Frequenz' },
    ];

    const initialValues = { newsletterEmail: email, newsletterReason: '' };

    const validationSchema = Yup.object().shape({
        newsletterEmail: Yup.string()
            .email(intl.formatMessage(subscribeMessages.validationEmailInvalid))
            .required(intl.formatMessage(subscribeMessages.validationEmailRequired)),
    });

    const [newsletterState, dispatch] = useReducer(
        newsletterReducer,
        newsletterConfig.initialState,
    );

    const { buttonState, confirmationContent, errorText, success } = newsletterState;

    const onSubmit = async ({
        newsletterEmail,
        newsletterReason,
    }: {
        newsletterEmail: string;
        newsletterReason: string;
    }): Promise<void> => {
        dispatch({ type: NewsletterReducerActionType.Loading });

        try {
            await newsletterUnsubscribe({
                email: newsletterEmail,
                reason: newsletterReason,
                additionalData: {
                    emailId: additionalData?.emailId,
                    launchListId: additionalData?.launchListId,
                    userId: additionalData?.userId,
                },
            });

            dispatch({
                type: NewsletterReducerActionType.Success,
                payload: confirmationText.replace(
                    newsletterConfig.userEmailPlaceholder,
                    newsletterEmail,
                ),
            });
            window.scrollTo(0, 0);
        } catch {
            dispatch({
                type: NewsletterReducerActionType.Error,
                payload: intl.formatMessage(subscribeMessages.errorTechnical),
            });
        }
    };

    if (success) {
        return (
            <div className="g-col-12">
                <Wysiwyg content={confirmationContent} />
            </div>
        );
    }

    return (
        <div className="g-col-sm-8">
            <Wysiwyg className="mb-3x" content={formText} />
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
            >
                <Form>
                    <FormikSelect
                        name="newsletterReason"
                        options={reasons}
                        label={intl.formatMessage(messages.reasonLabel)}
                    />

                    <FormikInput
                        className="mt-4x w-100"
                        name="newsletterEmail"
                        placeholder={intl.formatMessage(messages.emailLabel)}
                        disabled={buttonState === NewsletterButtonState.Loading}
                        errorText={errorText}
                    />

                    <div className="d-flex mt-4x">
                        <Button
                            className="flex-grow-1 flex-sm-grow-0"
                            type={ButtonType.Submit}
                            color={ButtonColor.Complementary}
                            disabled={buttonState === NewsletterButtonState.Loading}
                        >
                            <span>
                                <FormattedMessage defaultMessage="Abmelden" />
                            </span>
                        </Button>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

// @loadable/component supports only default export for now
// eslint-disable-next-line import/no-default-export
export default NewsletterUnsubscribe;
