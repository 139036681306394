/* eslint-disable no-void */
import { useField } from 'formik';

import { Checkbox, CheckboxRadioAlign } from '@jsmdg/yoshi';

type FormikCheckboxProps = {
    readonly name: string;
    readonly label: React.ReactNode;
    readonly className?: string;
    readonly textAlign?: CheckboxRadioAlign;
};

const FormikCheckbox = ({
    className,
    label,
    name,
    textAlign = CheckboxRadioAlign.Center,
}: FormikCheckboxProps): JSX.Element => {
    const [field, meta, helpers] = useField(name);
    const onClick = (): void => {
        void helpers.setValue(!field.value);
    };

    return (
        <Checkbox
            {...field}
            id={name}
            name={name}
            classNames={{ wrapperClassName: className }}
            textAlign={textAlign}
            checked={!!field.value}
            errorText={meta.error}
            isError={!!meta.error}
            onClick={onClick}
        >
            {label}
        </Checkbox>
    );
};

export { FormikCheckbox };
