/* eslint-disable canonical/filename-match-regex */
import { defineMessages } from 'react-intl';

export const messages = defineMessages({
    validationConsentRequired: {
        defaultMessage: 'Bitte akzeptiere die Bedingungen, um fortzufahren.',
    },
    validationEmailInvalid: {
        defaultMessage: 'Ungültige E-Mail Adresse.',
    },
    validationEmailRequired: {
        defaultMessage: 'E-Mail nicht eingetragen.',
    },
    errorTechnical: {
        defaultMessage:
            'Leider liegt derzeit ein technisches Problem vor. Bitte versuche es zu einem späteren Zeitpunkt nochmal.',
    },
    salutation: {
        defaultMessage: 'Anrede',
    },
    man: {
        defaultMessage: 'Herr',
    },
    woman: {
        defaultMessage: 'Frau',
    },
    firstname: {
        defaultMessage: 'Vorname',
    },
    lastname: {
        defaultMessage: 'Nachname',
    },
});
