export enum NewsletterReducerActionType {
    Loading = 'loading',
    Success = 'success',
    Error = 'error',
}

export enum NewsletterButtonState {
    Enabled = 'enabled',
    Loading = 'loading',
}

export const Salutation = {
    Mr: 'Mr',
    Ms: 'Ms',
} as const;
