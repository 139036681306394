import { NewsletterButtonState, NewsletterReducerActionType } from '../../shared/enums/newsletter';

export type NewsletterReducerState = {
    success: boolean;
    buttonState: NewsletterButtonState;
    errorText: string;
    confirmationContent: string;
};

export type NewsletterReducerAction = { type: NewsletterReducerActionType; payload?: string };

export const newsletterReducer = (
    state: NewsletterReducerState,
    action: NewsletterReducerAction,
): NewsletterReducerState => {
    switch (action.type) {
        case NewsletterReducerActionType.Loading:
            return {
                ...state,
                buttonState: NewsletterButtonState.Loading,
                errorText: '',
            };
        case NewsletterReducerActionType.Success:
            return {
                ...state,
                success: true,
                buttonState: NewsletterButtonState.Enabled,
                errorText: '',
                confirmationContent: action.payload || '',
            };
        case NewsletterReducerActionType.Error:
            return {
                ...state,
                success: false,
                buttonState: NewsletterButtonState.Enabled,
                errorText: action.payload || '',
                confirmationContent: '',
            };
        default:
            return state;
    }
};
