import { useField } from 'formik';

import { Dropdown, Select, type SelectOption } from '@jsmdg/yoshi';
import { getValidationState } from './util';

type FormikSelectProps = {
    readonly name: string;
    readonly label: string;
    readonly options: SelectOption[];
    readonly id?: string;
    readonly placeholder?: string;
    readonly className?: string;
    readonly as?: SelectRender;
};

export enum SelectRender {
    YoshiSelect = 'Select',
    YoshiDropdown = 'Dropdown',
}

export const FormikSelect = ({
    as = SelectRender.YoshiSelect,
    className,
    id,
    label,
    name,
    options,
    placeholder,
    ...restProps
}: FormikSelectProps): JSX.Element => {
    const [field, meta, helpers] = useField<React.Key>(name);
    const { error, touched } = meta;

    const selectProps = {
        ...field,
        ...restProps,
        name,
        className,
        options,
        label,
        helperText: error,
        showHelperText: !!error,
        validationState: getValidationState(touched, error),
    };

    return as === SelectRender.YoshiDropdown ? (
        <Dropdown {...selectProps} value={meta.value} onChange={async e => helpers.setValue(e)} />
    ) : (
        // @ts-expect-error -- React.Key mismatch
        <Select {...selectProps} id={id} placeholder={placeholder} />
    );
};
